<template>
  <div>
    <!-- {{currentItem}} -->
    <v-form @submit.prevent="onBeforeSubmit" ref="formRef">
      <v-row dense class="pt-4 pb-0 mb-0">
        <div class="center-text h1"> Procedimento: {{ currentItem.procedimento.procedimento }} {{ currentItem.procedimento.nomecomercial ? ` - "${currentItem.procedimento.nomecomercial}" `  : '' }} - Cód.: {{currentItem.procedimento.codigo}} - "Id: {{ currentItem.id }}"
        </div>
      </v-row>
      <v-row dense class="pt-4 pb-0 mb-0">
        <div class="center-text h1"> <span v-if="isTimestampValid">Data da Evolução: {{ formatTimestamp() }}</span> </div>
      </v-row>
      <v-row dense>
        <v-col>
          <v-select outlined required label="Status" v-model="currentItem.ativo" item-value="value"
            item-text="nome" :items="ativoOptions"></v-select>
        </v-col>
        <v-col v-if="!currentItem.procedimento.especial">
          <v-text-field label="Data da Evolução" outlined v-model="dateOnly" type="Date" >
          </v-text-field>          
        </v-col>
        <v-col v-if="!currentItem.procedimento.especial">
          <v-text-field label="Hora Inicial da Evolução" outlined v-model="horaInicioFormatted" type="time"></v-text-field>
        </v-col>
        <v-col v-if="!currentItem.procedimento.especial">
          <v-text-field label="Hora Final da Evolução" outlined v-model="horaFimFormatted" type="time"></v-text-field>
        </v-col>
        <v-col v-if="currentItem.aditivo">
          <v-select outlined label="Aditivo" v-model="currentItem.aditivo" item-value="value" disabled
          item-text="nome" :items="statusOptions"></v-select> 
        </v-col>
         <v-col v-if="currentItem.aditivo">
          <v-text-field label="Nrº Aditivo" outlined v-model="currentItem.nr_aditivo" type="Number"  disabled>
          </v-text-field>
        </v-col>
        <v-col v-if="currentItem.aditivo" >
          <v-text-field label="Data Início Aditivo" outlined v-model="currentItem.data_aditivo" type="Date"  disabled>
          </v-text-field>
        </v-col>
        <v-col>
          <v-select outlined label="Frequência" v-model="currentItem.frequencia" :items="frequencies"
          item-value="id" item-text="nome" required :rules="requiredField" ></v-select>
        </v-col>
        <v-col>
          <v-select outlined required label="Cobrança operadora" v-model="currentItem.cobranca" item-value="value"
          item-text="nome" :items="statusOptions"></v-select>
        </v-col>
        <v-col v-if="currentItem.procedimento.especial">
          <v-text-field label="Data Início Execução" outlined v-model="currentItem.data_inicio_especial" type="date" @input="updateSpecialQuantity()">
          </v-text-field>
        </v-col>
        <v-col v-if="currentItem.procedimento.especial">
          <v-text-field label="Data Fim Execução" outlined v-model="currentItem.data_fim_especial" type="date"  @input="updateSpecialQuantity()">
          </v-text-field>
        </v-col>
        <!-- <v-col v-if="currentItem.procedimento.especial">
          <v-text-field label="Data Execução" outlined v-model="currentItem.data_especial" type="date" >
          </v-text-field>
        </v-col> -->
        <v-col v-if="currentItem.procedimento.especial">
          <v-text-field label="Qtd." outlined v-model="currentItem.quantidade" type="Number" disabled >
          </v-text-field>
        </v-col>
        <v-col v-if="!currentItem.procedimento.especial">
          <v-select outlined required label="Offline" v-model="currentItem.offline" item-value="value"
            item-text="nome" :items="statusOptions"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vuetify-money outlined label="Valor cobrança" v-model="oldUnitPrice" :options="options" :rules="requiredField">
          </vuetify-money>
        </v-col>
        <v-col>
          <v-text-field label="Desconto" outlined v-model.lazy="currentItem.desconto" v-money="currencyMask">
            <template v-slot:append>%</template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Acréscimo" outlined v-model.lazy="currentItem.acrescimo" v-money="currencyMask">
            <template v-slot:append>%</template>
          </v-text-field>
        </v-col>
         <v-col>
          <v-text-field label="Valor Cobrança Ajustado" outlined v-model="procedureTotalValue" disabled>
            <template v-slot:prepend-inner>
              <span style="position: relative; top: 2.5px">R$</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-select outlined required label="Pagamento prestador" v-model="currentItem.pagamento" item-value="value"
          item-text="nome" :items="statusOptions"></v-select>
        </v-col>
        <v-col>
          <vuetify-money outlined label="Valor pagamento" v-model="currentItem.valor_pagamento" :options="options" :rules="requiredField">
          </vuetify-money>
        </v-col>
        <v-col v-if="currentItem.sessao.realizarrecursoglosa">
          <v-select outlined required label="Realizar Recurso Glosa" v-model="currentItem.recurso_glosa" item-value="value"
          item-text="nome" :items="statusOptions"></v-select>
        </v-col>
      </v-row>
      <v-row dense v-if="currentItem.recurso_glosa && currentItem.sessao.realizarrecursoglosa">
        <v-col cols="2">
          <v-text-field label="Sequência do Item " outlined v-model="currentItem.sequeItemGlosa" type="Number" class="yellow-background"></v-text-field>
        </v-col>
        <v-col cols="2">
         <v-text-field label="Data da Execução" outlined v-model="currentItem.data_execucao_glosa" type="Date" >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-autocomplete 
          outlined 
          label="Código do Recurso de Glosa" 
          v-model="currentItem.motivoglosa" 
          :items="codigorecurso" 
          item-value="id" 
          :item-text="(item) => `${item.codigo} - ${item.nome.replace(/\n/g, ' ')}`" 
          @change="updateMotivoGlosa"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Justificativa do Recurso de Glosa " outlined v-model="currentItem.justificativarecursoglosa"></v-text-field>
        </v-col>
        <v-col cols="2">
          <vuetify-money outlined label="Valor Recurso Glosa" v-model="currentItem.valor_glosa" :options="options" :rules="requiredField">
          </vuetify-money>
        </v-col>
      </v-row>
      <v-row dense v-if="currentItem.sessao.cobranca_parcial">
         <v-col cols="3">
         <v-select outlined required label="Realizar Cobrança Parcial" v-model="currentItem.cobranca_parcial" item-value="value"
            item-text="nome" :items="statusOptions"></v-select>
        </v-col>
        <v-col cols="3" v-if="currentItem.cobranca_parcial">
          <v-select :items="cobrancaParcialOptions" label="Nº Cobrança Parcial" outlined v-model="currentItem.n_cobranca_parcial" clearable ></v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-end">
          <ConfirmButton color="success" :loading="loading">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
  
<script>
import api from "../../../http";
import ConfirmButton from '../../ConfirmButton.vue';
import UtilsFunc from '../../../service/utilsFunc';
import '../../../assets/css/styles.css';

const { currencyToNumber, numberToCurrency } = UtilsFunc

export default {
  name: 'ProcedureForm',
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    currentItem: Object,
    procedures: Array,
    frequencies: Array,
    orcamennto: Object,
    statusOptions: Array,
    ativoOptions: Array,
    loading: Boolean
  },
  data() {
    return {
      options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      cobrancaParcialOptions: this.getCobrancaParcialOptions(),
      valorCobrancaRaw: 0, 
      valorPagamentoRaw: 0, 
      codigorecurso: [], // Aqui está agora no nível certo para ser reativo
      selectedRecursoGlosa: null,
      requiredField: [e => e !== undefined && e !== null && e !== '' || 'Obrigatório'],
      nrAditivo: [v => !!v || 'Nrº Aditivo é obrigatório'],
      currencyMask: {
        decimal: ',',
        thousands: '.',
        precision: 2,
      },
      dateOnly: "",
      horaInicioFormatted: "",  // Campo auxiliar para a hora de início
      horaFimFormatted: "",
    };
  },
  methods: {
    getCobrancaParcialOptions() {
      // Cria uma array com os valores de n_cp_1 a n_cp_4 que não são nulos
      const options = [
        this.orcamennto.n_cp_1,
        this.orcamennto.n_cp_2,
        this.orcamennto.n_cp_3,
        this.orcamennto.n_cp_4
      ].filter(value => value !== null);
      return options;
    },
    formatNumber(value) {
      if (typeof value === 'string') {
        value = value.replace(',', '.');
      }
      return parseFloat(value) || 0;
    },
    
    onBeforeSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }

      // Validar datas especiais
      if (this.currentItem.procedimento.especial) {
        const dataInicioEspecial = new Date(this.currentItem.data_inicio_especial);
        const dataFimEspecial = new Date(this.currentItem.data_fim_especial);
        const dataInicio = new Date(this.currentItem.sessao.data_inicio);
        const dataFim = new Date(this.currentItem.sessao.data_fim);

        if (dataInicioEspecial < dataInicio || dataFimEspecial > dataFim) {
          this.$toast.error("As datas de execução devem estar dentro do intervalo do orçamento.");
          return;
        }
      }

      // Verificar se o `timestamp` está entre `sessao.data_inicio` e `sessao.data_fim`
      const sessaoInicio = new Date(this.currentItem.sessao.data_inicio);
      const sessaoFim = new Date(this.currentItem.sessao.data_fim);
      const timestampDate = new Date(this.dateOnly);
      

      if (timestampDate < sessaoInicio || timestampDate > sessaoFim) {
        this.$toast.error("A Data da Evolução deve estar dentro do intervalo do orçamento.");
        return;
      }
      // Verificar se hora_fim é maior que hora_inicio
      if (this.horaInicioFormatted && this.horaFimFormatted) {
        const horaInicio = this.horaInicioFormatted.split(':');
        const horaFim = this.horaFimFormatted.split(':');

        const inicio = new Date();
        inicio.setHours(horaInicio[0], horaInicio[1]);

        const fim = new Date();
        fim.setHours(horaFim[0], horaFim[1]);

        if (fim <= inicio) {
          this.$toast.error("A hora final da evolução deve ser maior que a hora inicial da evolução.");
          return;  // Impede o envio do formulário
        }
      }
      this.currentItem.timestamp = this.currentItem.timestamp ? `${this.dateOnly}T00:00:00-03:00`: null;
      this.currentItem.hora_inicio = this.horaInicioFormatted ? `${this.horaInicioFormatted}:00` : null;
      this.currentItem.hora_fim = this.horaFimFormatted ? `${this.horaFimFormatted}:00` : null;
      const motivoGlosaId = this.currentItem.motivoglosa ? this.currentItem.motivoglosa.id : null;
      this.currentItem.sequeItemGlosa = this.currentItem.sequeItemGlosa ? this.currentItem.sequeItemGlosa.toString() : null;
      

      const valorCobrancaFinal = this.formatNumber(this.procedureTotalValue);
      const frequenciaId = this.currentItem?.frequencia?.id
      const fields = {
        cobranca: Boolean(this.currentItem.cobranca),
        pagamento: Boolean(this.currentItem.pagamento),
        sessao: Number(this.currentItem.guia),
        valor_cobranca: Number(this.oldUnitPrice),
        valor_cobranca_final: valorCobrancaFinal ,
        valor_pagamento: this.currentItem.valor_pagamento,
        frequencia: frequenciaId || this.currentItem.frequencia,
        procedimento: this.currentItem.procedimento.procedimento_id,
        aditivo: Boolean(this.currentItem.aditivo),
        nr_aditivo: this.currentItem.nr_aditivo,
        data_aditivo: this.currentItem.data_aditivo,
        offline: Boolean(this.currentItem.offline),
        ativo: Boolean(this.currentItem.ativo),
        id: this.currentItem.id,
        quantidade: Number(this.currentItem.quantidade),
        data_inicio_especial: this.currentItem.data_inicio_especial ? this.currentItem.data_inicio_especial : null,
        data_fim_especial:  this.currentItem.data_fim_especial ? this.currentItem.data_fim_especial : null,
        data_especial: this.currentItem.data_fim_especial ? this.currentItem.data_fim_especial : null,
        acrescimo: currencyToNumber(this.currentItem.acrescimo),
        desconto: currencyToNumber(this.currentItem.desconto),
        recurso_glosa: Boolean(this.currentItem.recurso_glosa),
        motivoglosa: motivoGlosaId,
        justificativarecursoglosa: this.currentItem.justificativarecursoglosa,
        timestamp: this.currentItem.timestamp ? this.currentItem.timestamp : null,
        hora_inicio: this.currentItem.hora_inicio,
        hora_fim: this.currentItem.hora_fim,
        sequeItemGlosa: this.currentItem.sequeItemGlosa,
        data_execucao_glosa: this.currentItem.data_execucao_glosa ? this.currentItem.data_execucao_glosa : null,
        valor_glosa: this.currentItem.valor_glosa ? Number(this.currentItem.valor_glosa) : null,
        cobranca_parcial: this.currentItem.cobranca_parcial ? Boolean(this.currentItem.cobranca_parcial) : null,
        n_cobranca_parcial: this.currentItem.n_cobranca_parcial ? Number(this.currentItem.n_cobranca_parcial) : null,
      }
      // console.log(fields)
      this.onSubmit(fields)
    },
    formatTimestamp() {
      if (this.currentItem.timestamp) {
        // Extrai apenas a parte da data (ignorando o fuso horário) para evitar o problema de "um dia a menos"
        const [year, month, day] = this.dateOnly.split('T')[0].split('-');

        const formattedDate = `${day}/${month}/${year}`;

        // Obtém o dia da semana sem alterar a data original
        const date = new Date(year, month - 1, day); // `month - 1` porque os meses no JavaScript são baseados em zero
        const weekDay = date.toLocaleDateString('pt-BR', { weekday: 'long' });

        return `${formattedDate} - ${weekDay.charAt(0).toUpperCase() + weekDay.slice(1)}`;
      }
      return '';
    },
    // Função para calcular a diferença de dias
    calculateDaysDifference(startDate, endDate) {
      const date1 = new Date(startDate);
      const date2 = new Date(endDate);
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    },
    // Função para atualizar a quantidade especial
    updateSpecialQuantity() {
      if (this.currentItem.data_inicio_especial && this.currentItem.data_fim_especial) {
        if (new Date(this.currentItem.data_inicio_especial) < new Date(this.currentItem.sessao.data_inicio)) {
          this.$toast.error('Data de início da execução não pode ser anterior à data de início do orçamento.');
          this.currentItem.data_inicio_especial = this.currentItem.sessao.data_inicio;
        }
        if (new Date(this.currentItem.data_fim_especial) > new Date(this.currentItem.sessao.data_fim)) {
          this.$toast.error('Data de fim da execução não pode ser posterior à data de fim do orçamento.');
          this.currentItem.data_fim_especial = this.currentItem.sessao.data_fim;
        }
        this.currentItem.quantidade = this.calculateDaysDifference(this.currentItem.data_inicio_especial, this.currentItem.data_fim_especial);
        this.currentItem.data_especial = this.currentItem.data_fim_especial;
      }
    },
    checkTimeConsistency() {
      if (this.horaInicioFormatted && this.horaFimFormatted) {
        const horaInicio = this.horaInicioFormatted.split(':');
        const horaFim = this.horaFimFormatted.split(':');

        const inicio = new Date();
        inicio.setHours(horaInicio[0], horaInicio[1]);

        const fim = new Date();
        fim.setHours(horaFim[0], horaFim[1]);

        if (fim <= inicio) {
          this.$toast.error("A hora final deve ser maior que a hora inicial.");
        }
      }
    },
     async getCodigoRecurso() {
      try {
        const { data } = await api.get('uteis/motivoglosa/');
        this.codigorecurso = data.filter(item => item.ativo);
        // console.log('Código Recurso:', this.codigorecurso); // Verifique os dados aqui
      } catch (error) {
        alert("Erro ao listar os recursos de glosa");
        console.log(error);
      }
     },
    updateMotivoGlosa(selectedId) {
      const selectedRecurso = this.codigorecurso.find(item => item.id === selectedId);
      if (selectedRecurso) {
        this.currentItem.motivoglosa = selectedRecurso;  // Agora é um objeto completo
        // console.log('Motivo da Glosa Atualizado:', this.currentItem.motivoglosa);
      }
    },
  },
  computed: {
    isTimestampValid() {
      // Retorna verdadeiro se `timestamp` existe e é uma string não-vazia.
      return this.currentItem.timestamp && typeof this.currentItem.timestamp === 'string';
    },
    procedureTotalValue() {
      const addition = currencyToNumber(this.currentItem.acrescimo) || 0
      const discount = currencyToNumber(this.currentItem.desconto) || 0
      const baseValue = currencyToNumber(this.currentItem.valor_cobranca)
      const result =
       (baseValue + (baseValue * (addition / 100)) - (baseValue * (discount / 100))) * this.currentItem.quantidade
      return numberToCurrency(result)
    }
  },
  beforeMount() {
    if (this.currentItem.timestamp) {
      const date = new Date(this.currentItem.timestamp);
      // Formatar como 'yyyy-MM-dd' para o campo de data
      this.dateOnly = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }
    if (this.currentItem.hora_inicio) {
      this.horaInicioFormatted = this.currentItem.hora_inicio.substring(0, 5); // Extrair apenas 'HH:MM'
    }
    
    if (this.currentItem.hora_fim) {
      this.horaFimFormatted = this.currentItem.hora_fim.substring(0, 5); // Extrair apenas 'HH:MM'
    }
    this.oldUnitPrice = this.currentItem.valor_cobranca
    this.currentItem.valor_cobranca = numberToCurrency(this.currentItem.valor_cobranca)
    this.currentItem.acrescimo = numberToCurrency(this.currentItem.acrescimo)
    this.currentItem.desconto = numberToCurrency(this.currentItem.desconto)
  },
  watch: {
    'currentItem.valor_cobranca': function (newValue) {
      this.valorCobrancaRaw = newValue;
    },
    'currentItem.valor_pagamento': function (newValue) {
      this.valorPagamentoRaw = newValue;
    },
    'currentItem.aditivo': function (newVal) {
      if (!newVal) {
        this.currentItem.nr_aditivo = null;
      }
    },
    'currentItem.data_inicio_especial': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (new Date(newVal) < new Date(this.currentItem.sessao.data_inicio)) {
          this.currentItem.data_inicio_especial = this.currentItem.sessao.data_inicio;
        } else {
          this.updateSpecialQuantity();
        }
      }
    },
    'currentItem.data_fim_especial': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (new Date(newVal) > new Date(this.currentItem.sessao.data_fim)) {
          this.currentItem.data_fim_especial = this.currentItem.sessao.data_fim;
        } else {
          this.updateSpecialQuantity();
        }
      }
    },
    'currentItem.cobranca_parcial': function(newVal) {
      if (!newVal) {
        this.currentItem.n_cobranca_parcial = null;
      }
    },
    
  },
  mounted() {
    this.getCodigoRecurso();
  }
}
</script>
  
<style scoped>
.center-text {
  text-align: center;
  font-size: 20px;
}
</style>

